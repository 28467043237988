import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Mocha Delight Coffee House
			</title>
			<meta name={"description"} content={"Де кожен ковток викликає радість"} />
			<meta property={"og:title"} content={"Mocha Delight Coffee House"} />
			<meta property={"og:description"} content={"Де кожен ковток викликає радість"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66583a008e2e8e00217c3706/images/2-2.jpg?v=2024-05-30T11:46:42.484Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66583a008e2e8e00217c3706/images/2-2.jpg?v=2024-05-30T11:46:42.484Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66583a008e2e8e00217c3706/images/2-2.jpg?v=2024-05-30T11:46:42.484Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66583a008e2e8e00217c3706/images/2-2.jpg?v=2024-05-30T11:46:42.484Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66583a008e2e8e00217c3706/images/2-2.jpg?v=2024-05-30T11:46:42.484Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66583a008e2e8e00217c3706/images/2-2.jpg?v=2024-05-30T11:46:42.484Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66583a008e2e8e00217c3706/images/2-2.jpg?v=2024-05-30T11:46:42.484Z"} />
		</Helmet>
		<Components.Contact />
		<Components.Nav />
		<Components.Hero>
			<Override slot="button" href="/contacts">
				Контакти
			</Override>
			<Override slot="text1">
				Відчуйте діапазон смаку
			</Override>
			<Override slot="text">
				Послуги
			</Override>
			<Override slot="text2">
				У кав’ярні Mocha Delight ми пишаємося тим, що пропонуємо різноманітне меню, яке задовольнить усіх любителів кави. Наші пропозиції створені з ретельністю та точністю, щоб гарантувати, що кожен ковток і шматок будуть чудовими враженнями. Ось докладніше про те, що ви можете очікувати, відвідавши нас.
			</Override>
		</Components.Hero>
		<Section padding="90px 0 100px 0" quarkly-title="Price-18" background="linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66583a008e2e8e00217c3706/images/3-3.jpg?v=2024-05-30T11:46:42.490Z) 0% 0% /cover no-repeat scroll padding-box">
			<Override slot="SectionContent" />
			<Text margin="0px 0px 20px 0px" text-align="center" font="--headline2" color="--light">
				Огляд меню
			</Text>
			<Text
				margin="0px 0px 70px 0px"
				text-align="center"
				font="--lead"
				color="--light"
				width="700px"
				align-self="center"
				md-align-self="auto"
				max-width="100%"
			>
				У кав’ярні Mocha Delight ми пишаємося тим, що пропонуємо різноманітне меню, яке задовольнить усіх любителів кави.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="0 34px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="35px 0"
				height="auto"
				grid-template-rows="auto"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="auto"
						display="flex"
						flex-direction="column"
						align-items="center"
						height="auto"
					>
						<Image
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							src="https://images.unsplash.com/photo-1506619216599-9d16d0903dfd?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
							srcSet="https://images.unsplash.com/photo-1506619216599-9d16d0903dfd?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1506619216599-9d16d0903dfd?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1506619216599-9d16d0903dfd?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1506619216599-9d16d0903dfd?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1506619216599-9d16d0903dfd?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1506619216599-9d16d0903dfd?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1506619216599-9d16d0903dfd?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="auto"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
							height="auto"
						>
							<Text
								margin="0px 0px 10px 0px"
								color="--darkL1"
								text-align="center"
								font="--lead"
								text-transform="uppercase"
							>
								Кавові напої
							</Text>
							<Text margin="0px 0px 10px 0px" color="--darkL1" text-align="left" font="--base">
								Твори еспресо: Наші еспресо-напої, виготовлені майстрами, є серцевиною нашого меню. Кожен шот насичений і насичений, створюючи ідеальну основу для різноманітних напоїв.
								<br />
								{" "}Класичний еспресо: чистий і насичений еспресо.
 Капучіно: Збалансована суміш еспресо, пареного молока та піни.
								<br />
								{" "}Латте: Гладкий еспресо з парним молоком і легким шаром піни.
								<br />
								Speciality Coffee: Ці напої пропонують унікальний відтінок традиційних улюблених напоїв, ідеально підходить для тих, хто хоче спробувати щось нове.
								<br />
								{" "}Mocha Delight: Еспресо в поєднанні з насиченим шоколадом і парним молоком.
								<br />
								{" "}Caramel Macchiato: Чудова суміш еспресо, карамелі та молока.
 Vanilla Latte: М’який еспресо з відтінком ванілі.
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								color="--darkL1"
								font="normal 300 16px/1.5 --fontFamily-sansTrebuchet"
								text-align="center"
								display="none"
							>
								Твори еспресо: Наші еспресо-напої, виготовлені майстрами, є серцевиною нашого меню. Кожен шот насичений і насичений, створюючи ідеальну основу для різноманітних напоїв.
 Класичний еспресо: чистий і насичений еспресо.
 Капучіно: Збалансована суміш еспресо, пареного молока та піни.
 Латте: Гладкий еспресо з парним молоком і легким шаром піни.
Speciality Coffee: Ці напої пропонують унікальний відтінок традиційних улюблених напоїв, ідеально підходить для тих, хто хоче спробувати щось нове.
 Mocha Delight: Еспресо в поєднанні з насиченим шоколадом і парним молоком.
 Caramel Macchiato: Чудова суміш еспресо, карамелі та молока.
 Vanilla Latte: М’який еспресо з відтінком ванілі.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							src="https://images.unsplash.com/photo-1534349842105-3ca31a513556?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
							srcSet="https://images.unsplash.com/photo-1534349842105-3ca31a513556?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1534349842105-3ca31a513556?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1534349842105-3ca31a513556?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1534349842105-3ca31a513556?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1534349842105-3ca31a513556?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1534349842105-3ca31a513556?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1534349842105-3ca31a513556?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
							height="auto"
						>
							<Text
								margin="0px 0px 10px 0px"
								color="--darkL1"
								text-align="center"
								font="--lead"
								text-transform="uppercase"
							>
								Холодні напої
							</Text>
							<Text margin="0px 0px 10px 0px" color="--darkL1" text-align="left" font="--base">
								Кава з льодом: Освіжаюча та підбадьорлива, наша кава з льодом ідеально підходить для спекотного дня.{"\n"}
								<br />
								{" "}Iced Latte: Еспресо з охолодженим молоком, подається з льодом.{"\n"}
								<br />
								{" "}Cold Brew: Кава повільного приготування, гладка та менш кисла.{"\n"}
								<br />
								{" "}Мокко з льодом: Охолоджене еспресо з шоколадом і молоком, подається з льодом.{"\n"}
								<br />
								Змішані напої: Ці заморожені смаколики ідеально підходять для солодкого.{"\n"}
								<br />
								{" "}Frappé: вершковий кавовий напій зі смаком на вибір.{"\n"}
								<br />
								{" "}Смузі: суміші свіжих фруктів для освіжаючого та здорового варіанту.{"\n"}
								<br />
								{" "}Молочні коктейлі: Класичні коктейлі, виготовлені з насиченого морозива та молока.
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								color="--darkL1"
								font="normal 300 16px/1.5 --fontFamily-sansTrebuchet"
								text-align="center"
								display="none"
							>
								Чаї: вибір вишуканих чаїв для тих, хто віддає перевагу чомусь іншому.
 Зелений чай:  Свіжий і підбадьорливий.
 Chai Latte: суміш пряного чаю з парним молоком.
 Трав’яні чаї:  Різні смаки для заспокоєння та розслаблення.
Варіанти без кави: ми також пропонуємо різноманітні інші напої.
 Гарячий шоколад: насичений і кремовий шоколадний напій.
 Пароварка: Тепле молоко зі смаком на вибір.
 Лимонад: свіжовичавлений для терпкого та солодкого смаку.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="0 34px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="35px 0"
				height="auto"
				grid-template-rows="auto"
				margin="2rem 0px 0px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="auto"
						display="flex"
						flex-direction="column"
						align-items="center"
						height="auto"
					>
						<Image
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							src="https://images.unsplash.com/photo-1571934811356-5cc061b6821f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
							srcSet="https://images.unsplash.com/photo-1571934811356-5cc061b6821f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1571934811356-5cc061b6821f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1571934811356-5cc061b6821f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1571934811356-5cc061b6821f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1571934811356-5cc061b6821f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1571934811356-5cc061b6821f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1571934811356-5cc061b6821f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="auto"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
							height="auto"
						>
							<Text
								margin="0px 0px 10px 0px"
								color="--darkL1"
								text-align="center"
								font="--lead"
								text-transform="uppercase"
							>
								Чаї та інші напої
							</Text>
							<Text margin="0px 0px 10px 0px" color="--darkL1" text-align="left" font="--base">
								Чаї: вибір вишуканих чаїв для тих, хто віддає перевагу чомусь іншому.{"\n"}
								<br />
								{" "}Зелений чай:  Свіжий і підбадьорливий.{"\n"}
								<br />
								{" "}Chai Latte: суміш пряного чаю з парним молоком.
 Трав’яні чаї:  Різні смаки для заспокоєння та розслаблення.{"\n"}
								<br />
								Варіанти без кави: ми також пропонуємо різноманітні інші напої.{"\n"}
								<br />
								{" "}Гарячий шоколад: насичений і кремовий шоколадний напій.{"\n"}
								<br />
								{" "}Пароварка: Тепле молоко зі смаком на вибір.
 Лимонад: свіжовичавлений для терпкого та солодкого смаку.
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								color="--darkL1"
								font="normal 300 16px/1.5 --fontFamily-sansTrebuchet"
								text-align="center"
								display="none"
							>
								Твори еспресо: Наші еспресо-напої, виготовлені майстрами, є серцевиною нашого меню. Кожен шот насичений і насичений, створюючи ідеальну основу для різноманітних напоїв.
 Класичний еспресо: чистий і насичений еспресо.
 Капучіно: Збалансована суміш еспресо, пареного молока та піни.
 Латте: Гладкий еспресо з парним молоком і легким шаром піни.
Speciality Coffee: Ці напої пропонують унікальний відтінок традиційних улюблених напоїв, ідеально підходить для тих, хто хоче спробувати щось нове.
 Mocha Delight: Еспресо в поєднанні з насиченим шоколадом і парним молоком.
 Caramel Macchiato: Чудова суміш еспресо, карамелі та молока.
 Vanilla Latte: М’який еспресо з відтінком ванілі.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							src="https://images.unsplash.com/photo-1565182363525-f95648bbeee0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
							srcSet="https://images.unsplash.com/photo-1565182363525-f95648bbeee0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1565182363525-f95648bbeee0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1565182363525-f95648bbeee0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1565182363525-f95648bbeee0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1565182363525-f95648bbeee0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1565182363525-f95648bbeee0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1565182363525-f95648bbeee0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
							height="auto"
						>
							<Text
								margin="0px 0px 10px 0px"
								color="--darkL1"
								text-align="center"
								font="--lead"
								text-transform="uppercase"
							>
								Випічка та закуски
							</Text>
							<Text margin="0px 0px 10px 0px" color="--darkL1" text-align="left" font="--base">
								Свіжоспечена випічка: Ідеальний гарнір до кави.
 Круасани: пластівчасті та маслянисті, щодня свіжі.
 Мафіни: М’які та ароматні, з різними варіантами.
 Булочки: Легкі та розсипчасті, ідеальні для ранкового частування.{"\n"}
								<br />
								Солоні закуски:, коли вам потрібно щось ситніше.
 Сандвічі: зі свіжих інгредієнтів і різноманітними начинками.{"\n"}
								<br />
								{" "}Кіші: смачні солоні пироги, ідеальні для будь-якого часу доби.{"\n"}
								<br />
								{" "}Салати: свіжі та здорові страви, які доповнять ваш напій.
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								color="--darkL1"
								font="normal 300 16px/1.5 --fontFamily-sansTrebuchet"
								text-align="center"
								display="none"
							>
								Чаї: вибір вишуканих чаїв для тих, хто віддає перевагу чомусь іншому.
 Зелений чай:  Свіжий і підбадьорливий.
 Chai Latte: суміш пряного чаю з парним молоком.
 Трав’яні чаї:  Різні смаки для заспокоєння та розслаблення.
Варіанти без кави: ми також пропонуємо різноманітні інші напої.
 Гарячий шоколад: насичений і кремовий шоколадний напій.
 Пароварка: Тепле молоко зі смаком на вибір.
 Лимонад: свіжовичавлений для терпкого та солодкого смаку.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Nav />
		<Components.Contact />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66329762fdcae00021e1d75a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});